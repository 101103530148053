<template>
  <v-item-group>
    <v-container class="pt-10">
      <v-row>
        <v-col v-for="n in lectures" :key="n.subjectGuid" cols="12" lg="4" md="6" sm="12">
          <v-card
            class="d-flex align-center rounded-xl"
            height="100"
            @click="showDegree(n.subjectGuid)"
            outlined
          >
            <v-scroll-y-transition>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="mb-1">
                    {{ n.subjectName }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                  <img src="../../assets/exam.svg" />
                </v-list-item-avatar>
              </v-list-item>
            </v-scroll-y-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      transition="dialog-bottom-transition"

      max-width="800"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          {{
            degrees[0] ? `${$t('exam.GradesOfSubject')} ${degrees[0].subjectName}` : $t('exam.GradesOfSubject')
          }}</v-toolbar
        >
        <v-card-text class="mt-4">
          <v-data-table
            :headers="headers"
            :items="degrees"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.examType`]="{ item }">
              <v-chip v-if="item.examType == 0" color="blue-grey" dark>{{$t('degrees.daily')}}</v-chip>
              <v-chip v-else-if="item.examType == 1" color="blue" dark>{{$t('degrees.Monthly')}}</v-chip>
              <v-chip v-else-if="item.examType == 2" color="cyan" dark>{{$t('degrees.midFinal')}}</v-chip>
              <v-chip v-else-if="item.examType == 3" color="teal" dark>{{$t('degrees.final')}}</v-chip>
            </template>
            <template v-slot:[`item.examDegree`]="{ item }">
              <v-chip v-if="item.examDegree >= 50" color="green" dark>{{
                item.examDegree
              }}</v-chip>
              <v-chip v-else color="pink lighten-1" dark>{{ item.examDegree }}</v-chip>
            </template>
            <template v-slot:[`item.examDate`]="{ item }">
              {{ item.examDate.substr(0, 10) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false"> {{$t('close')}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-item-group>
</template>

<script>
import { bus } from '../../main'

export default {
  data() {
    return {
      lectures: [],
      overlay: false,
      dialog: false,
      degrees: [],
      headers: [
        {
          text: this.$t('degrees.month'),
          align: "start",
          sortable: false,
          value: "examMonth",
        },
        { text: this.$t('degrees.examType'), value: "examType" },
        { text: this.$t('degrees.theDegree'), value: "examDegree" },
        { text: this.$t('exam.Observation'), value: "notex" },
         { text: this.$t('home.lecTeacher'), value: "teacherName" },
        { text: this.$t('date'), value: "examDate" },
      ],
    };
  },
  props: ["id"],
  methods: {
    showDegree(subjectGuid) {
    //  this.id=subjectGuid;
      this.overlay = true;
      this.axios.get(`Degrees/Get?subjectGuid=${subjectGuid}`)
        .then((res) => {
          this.degrees = res.data.data;
          // console.log(this.degrees);

          this.dialog = true;
        })
        .catch(() => {})
        .finally(() => {
          this.overlay = false;
        });
    },
      filterDuplicates(items) {
      return items.filter(
        (thing, index, self) => index === self.findIndex(t => t.subjectGuid === thing.subjectGuid)
      );
    },
    getLec() {
      this.overlay = true;
      this.axios.get("Subjects/Get")
        .then((res) => {
          if (this.isInRole(2)) {
            this.lectures = res.data.data.subjects;
          } else {
            this.lectures = this.filterDuplicates(res.data.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
  },
  created() {
    this.getLec();

    bus.$on('showDegreeDialog', (data) => {
       this.showDegree(data);
    })
  },
};
</script>

<style></style>
